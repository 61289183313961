/**
 * src/js/modules/easy_contact.js
 *
 * @author Johanes Braun <j.braun@agentur-halma.de>
 * @package easy-contact
 * @version 2022-05-12
 */
export default class EasyContact {

	constructor(userOptions = null) {

		const defaultOptions = {
			language: document.documentElement.lang,
			title: {
				mailto: { 
					'de': 'E-Mail verfassen an <{{}}> mit',
					'en': 'Compose email to <{{}}> with'
				},
				tel: {
					'de': 'Anruf/Nachricht an <<{{}}>> mit',
					'en': 'Call or text <{{}}> with'
				}
			},
			actions: {
				mailto: [
					{
						name: {
							'de': 'meinem Standard E-Mail-Programm',
							'en': 'my default email client'
						},
						callback: this.actionDefaultEmailClient,
						closeDialog: true
					},
					{
						name: {
							de:	'Google Mail (Gmail)',
							en: 'Google Mail (Gmail)'
						},
						callback: this.actionGoogleMail,
						closeDialog: true
					},
					{
						name: {
							de: 'Outlook',
							en: 'Outlook'
						},
						callback: this.actionOutlook,
						closeDialog: true
					},
					{
						name: {
							'de': 'E-Mail Adresse in die Zwischenablage kopieren',
							'en': 'Copy email adress to clipboard'
						},
						successText: {
							'de': '✔ kopiert',
							'en': '✔ copied'
						},
						callback: this.actionCopy,
						closeDialog: false
					}
				],
				tel: [
					{
						name: {
							'de': 'meiner Standard Telefon-App',
							'en': 'my default phone app'
						},
						callback: this.actionDefaultPhoneApp,
						closeDialog: true
					},
					{
						name: {
							'de': 'WhatsApp',
							'en': 'WhatsApp'
						},
						callback: this.actionWhatsApp,
						closeDialog: true
					},
					{
						name: {
							'de': 'Telefonnummer in die Zwischenablage kopieren',
							'en': 'Copy phone number to clipboard'
						},
						callback: this.actionCopy,
						successText: {
							'de': '✔ kopiert',
							'en': '✔ copied'
						},
						closeDialog: false
					}
				]
			},
			copyAction: true
		};

		this.options = extend(defaultOptions, userOptions);


		this.mailtoSelector = '[href^=mailto\\:],[href^=tel\\:]';
	

		this.links = document.querySelectorAll(this.mailtoSelector);
		this.links.forEach(link => {
			link.addEventListener('click', (e) => {
				e.preventDefault();
				this.currentLink = e.target.closest('a');
				this.currentLinkHref = this.currentLink.getAttribute('href');
				const match= this.currentLinkHref.match(/(tel|mailto):(.*)/);
				this.currentLinkSubject = match[2];
				if (this.currentLinkHref.match(/^mailto\:/)) {
					this.currentLinkType = 'mailto';
				}
				else if (this.currentLinkHref.match(/^tel\:/)) {
					this.currentLinkType = 'tel';
				}
				else {
					this.currentLinkType = 'unknown';
				}
				this.onClick();
			});
		});
		this.dialog = document.createElement('dialog');
		this.dialog.className = 'easy-contact-dialog';
		this.dialogHeader = document.createElement('header');
		this.dialogHeaderText = document.createElement('span');
		this.dialogBody = document.createElement('div');
		this.dialogBodyText = document.createElement('div');
		this.dialogList = document.createElement('ul');
		this.dialogFooter = document.createElement('footer');
		this.dialogCloseBtn = document.createElement('button');
		this.dialogCloseBtn.innerHTML = '&times;';
		this.dialogCloseBtn.addEventListener('click', e => {
			this.closeDialog();
		});
		this.dialogHeader.appendChild(this.dialogHeaderText);
		this.dialogHeader.appendChild(this.dialogCloseBtn);

		this.dialogBody.appendChild(this.dialogBodyText);
		this.dialogBody.appendChild(this.dialogList);

		this.dialog.appendChild(this.dialogHeader);
		this.dialog.appendChild(this.dialogBody);
		this.dialog.appendChild(this.dialogFooter);

		this.dialog.style.zIndex = '32767';

		if (this.options.addCopyAction) {
		}
		document.body.appendChild(this.dialog);
		document.addEventListener('keyup', e => {
			if (e.keyCode == 27) {
				this.closeDialog();
			}
		});
	}

	onClick() {
		this.showDialog();
	}

	showDialog() {

		this.dialogList.innerHTML = '';
		let actions = null;

		switch (this.currentLinkType) {
			case 'mailto':
				this.dialogHeaderText.innerText = this.options.title.mailto[this.options.language].replace(/{{}}/, this.currentLinkSubject);
				actions = this.options.actions.mailto;
				break;
			case 'tel':
				this.dialogHeaderText.innerText = this.options.title.tel[this.options.language].replace(/{{}}/, this.currentLinkSubject);
				actions = this.options.actions.tel;
				break;
			default:
				break;
		}

		if (!actions) {
			console.log(`invalid link type: ${this.currentLinkType}`);
			return;
		}

		for (var i = 0; i < actions.length; i++) {
			this.addAction(actions[i]);
		}

		this.dialog.showModal();
		// this.dialog.toggleAttribute('open', true);
	}

	closeDialog() {
		// this.dialog.toggleAttribute('open', false);
		this.dialog.close();
	}

	addAction(action) {
		let item = document.createElement('li');
		let link = document.createElement('a');
		link.setAttribute('href', 'javascript:void(0)');

		link.innerText = action.name[this.options.language];
		link.addEventListener('click', (e) => {
			e.preventDefault();
			this.actionSuccess = this.doAction(action, e.target);
		});
		item.appendChild(link);
		this.dialogList.appendChild(item);
	}



	doAction(action, dialogButton) {

		// For correct binding `this` the callback 
		// must be assigned to a variable first 
		// (@see https://thenewstack.io/mastering-javascript-callbacks-bind-apply-call/)
		let cb = action.callback.bind(this);
		let ret = cb(action, dialogButton);

		if (action.closeDialog) {
			this.closeDialog();
		}

		return ret;
	}



	//////////////////////
	//  Mailto actions  //
	//////////////////////

	actionDefaultEmailClient(action, dialogButton) {
		window.location = this.currentLinkHref;
		return true;
	}

	actionGoogleMail(action, dialogButton) {
		const url = `https://mail.google.com/mail/?extsrc=mailto&url=${this.currentLinkHref}`;
		window.open(url, '_blank', 'noopener, noreferrer');
		return true;
	}
	
	actionOutlook(action, dialogButton) {
		alert("Not implemented yet!");
		return true;
	}
	


	/////////////////////
	//  Phone actions  //
	/////////////////////
	
	actionDefaultPhoneApp(action, dialogButton) {
		window.location = this.currentLinkHref;
		return true;
	}

	actionWhatsApp(action, dialogButton) {
		alert("Not implemented yet!");
		return true;
	}

	actionCopy(action, dialogButton) {
		navigator.clipboard.writeText(this.currentLinkSubject);

		if (action.successText[this.options.language]) {
			dialogButton.innerHTML = action.successText[this.options.language];
		}

		return true;
	}
}
var extend = function () {

// Variables
var extended = {};
var deep = false;
var i = 0;
var length = arguments.length;

// Check if a deep merge
if ( Object.prototype.toString.call( arguments[0] ) === '[object Boolean]' ) {
	deep = arguments[0];
	i++;
}

// Merge the object into the extended object
var merge = function (obj) {
	for ( var prop in obj ) {
		if ( Object.prototype.hasOwnProperty.call( obj, prop ) ) {
			// If deep merge and property is an object, merge properties
			if ( deep && Object.prototype.toString.call(obj[prop]) === '[object Object]' ) {
				extended[prop] = extend( true, extended[prop], obj[prop] );
			} else {
				extended[prop] = obj[prop];
			}
		}
	}
};

// Loop through each object and conduct a merge
for ( ; i < length; i++ ) {
	var obj = arguments[i];
	merge(obj);
 }
	   return extended;
};


new EasyContact({
	// language: 'fr',
	// title: {
	// 	mailto: {
	// 		'fr': 'Je suis texte francaise'
	// 	}
	// }
});
